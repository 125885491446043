import { Chip, Typography } from '@mui/material'
import React, { FC } from 'react'
/* eslint-disable consistent-return */
import { Stack } from '@mui/material'
import { useSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { importExcelActions } from 'store/slices/import_excel'
import { COLORS } from 'utils'
interface Props {
  code: string
}
export const ColumnSelect: FC<Props> = ({ code }) => {
  const { import_list } = useSelector((x) => x.import_excel)
  const import_excel = import_list?.find((u) => u.code === code)
  var template = import_excel?.config?.template
  const dispatch = useDispatch()

  if (!template) {
    return <div />
  }

  return (
    <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} sx={{}}>
      {template?.columns?.map(({ label, key, required }) => (
        <Chip
          label={
            <Typography
              style={{ fontSize: '12px', fontWeight: '400', color: import_excel.whitelist_column_keys?.includes(key) ? COLORS.white : COLORS.DarkCharcoal }}
            >
              {label + (required ? '(*)' : '')}
            </Typography>
          }
          size="small"
          onClick={() => dispatch(importExcelActions.selectColumn({ code, column_key: key }))}
          sx={{
            borderRadius: '2px',
            padding: '0px',
            margin: '2px',
            backgroundColor: import_excel.whitelist_column_keys?.includes(key) ? COLORS.LightSkyBlue : COLORS.bright_Gray,
            '&:hover': {
              opacity: 0.9
            }
          }}
        />
      ))}
    </Stack>
  )
}

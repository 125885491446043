import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { DatePickerCustom, DialogMain, DropdownPicker, MyTextField, UIDialogRef } from 'components'
import { InputConfig } from 'models/table'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store/slices/table'
import { useTableConfig, useTableData } from '../hook'
import { DateTimePickerCustom } from 'components/DateTimePicker'

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          filter: 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15))',
          border: '1px solid #EEEEEE',
          borderRadius: '6px',
          marginTop: '8px',
          padding: 0
        }
      }
    }
  }
})
export interface FormRef {
  open(): void
  close(): void
}
export function FilterDialog({ open, onClose, code }: any) {
  const { config } = useTableConfig({ code })
  const { table } = useTableData(code)
  const [currentFilter, setCurrentFilter] = useState<any>({})
  useEffect(() => {
    setCurrentFilter(table?.filter?.data ?? {})
  }, [table?.filter?.data])

  const inputs = config?.filters?.inputs ?? []

  const dispatch = useDispatch()

  const handleChange = useCallback(
    (fields: any) => {
      setCurrentFilter({
        ...currentFilter,
        ...fields
      })
    },
    [currentFilter]
  )
  const applyFilter = useCallback(() => {
    dispatch(tableActions.changeFilter({ data: currentFilter, name: code }))
    onClose()
  }, [code, currentFilter, dispatch, onClose])

  const resetFilter = useCallback(() => {
    dispatch(tableActions.resetFilter(code))
    onClose()
  }, [code, dispatch, onClose])

  const renderField = useCallback(
    ({ type, url, data, label, name, filterFunc, hidden, getLabel, getCaption }: InputConfig<any>) => {
      const value = currentFilter[name]
      if (type === 'dropdown') {
        return (
          <DropdownPicker
            label={label}
            filterFunc={filterFunc}
            url={url}
            options={data}
            selectOption={(item) => {
              handleChange({ [name]: item })
            }}
            getOptionLabel={(option: any) => getLabel?.(option) ?? ''}
            getOptionCaption={(option: any) => getCaption?.(option) ?? ''}
            // TODO: Dynamic compare
            isOptionEqualToValue={(a, b) => a === b ?? a?.id === b?.id}
            value={value}
          />
        )
      }
      if (type === 'dropdown_multiple') {
        return (
          <DropdownPicker
            multiple
            label={label}
            url={url}
            options={data}
            selectOptions={(items) => {
              handleChange({ [name]: items })
            }}
            getOptionLabel={(option: any) => getLabel?.(option) ?? ''}
            getOptionCaption={(option: any) => getCaption?.(option) ?? ''}
            // TODO: Dynamic compare
            isOptionEqualToValue={(a, b) => a === b ?? a?.id === b?.id}
            value={value}
          />
        )
      }
      if (type === 'input_date') {
        return (
          <DatePickerCustom
            label={label}
            placeholder={''}
            value={value}
            handleChange={(val) => {
              handleChange({ [name]: val })
            }}
          />
        )
      }
      if (type === 'input_datetime') {
        return (
          <DateTimePickerCustom
            label={label}
            placeholder={''}
            value={value}
            handleChange={(val) => {
              handleChange({ [name]: val })
            }}
          />
        )
      }
      if (type === 'input_text') {
        return (
          <MyTextField
            // width="100%"
            label={label}
            value={value}
            textcolor="#A3A3A3"
            placeholder="Tìm kiếm"
            backgroundColor="#F5F5F5"
            handleChange={(val) => {
              handleChange({ [name]: val })
            }}
            iconstart={<SearchIcon sx={{ marginRight: '4px' }} color="disabled" />}
          />
        )
      }
      if (type === 'input_number') {
        return (
          <MyTextField
            // width="100%"
            type="number"
            label={label}
            value={value}
            textcolor="#A3A3A3"
            placeholder="Nhập số"
            backgroundColor="#F5F5F5"
            handleChange={(val) => {
              handleChange({ [name]: val })
            }}
            iconstart={<SearchIcon sx={{ marginRight: '4px' }} color="disabled" />}
          />
        )
      }
      return undefined
    },
    [currentFilter, handleChange]
  )
  const dialogRef = useRef<UIDialogRef>(null)
  useEffect(() => {
    if (open) {
      dialogRef?.current?.show()
    } else {
      dialogRef?.current?.hide()
    }
  }, [open])

  if (!config) {
    return <></>
  }

  return (
    <DialogMain ref={dialogRef} label="Bộ lọc" onClose={onClose} onSubmit={applyFilter} onReset={resetFilter} onCancel={onClose}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} rowSpacing={2}>
          {inputs
            .filter((item) => !item.hidden)
            .map((item: InputConfig<any>) => (
              <Grid sm={6} sx={{ py: '10px', px: '20px' }}>
                {renderField(item)}
              </Grid>
            ))}
        </Grid>
      </ThemeProvider>
    </DialogMain>
  )
}

import { Stack, Tooltip, Typography } from '@mui/material'
import { Button, MyTextArea } from 'components'
import { useSelector } from 'hooks'
import { EnumCareLogType } from 'models'
import { useCustomerCare } from 'hooks/customer_care'
import React, { useEffect, useState } from 'react'
import { COLORS } from 'utils'
import { CareLog } from './care_log'
import { Header } from './header'
import { getChangeLogsByType } from './common'

export function CarerNote() {
  const { care } = useSelector((x) => x.care)
  const { createLog, getDetail } = useCustomerCare()
  if (!care) {
    return <div />
  }
  const { carer, unit_carer, logs, carer_note, id } = care
  const [content, setContent] = useState<string>(carer_note)

  useEffect(() => {
    setContent(carer_note)
  }, [carer_note])

  const changeLogs = getChangeLogsByType(care, EnumCareLogType.CHANGE_NOTE)
  console.log('Note: ', content, care, carer_note)
  return (
    <Stack>
      <Stack direction={'row'}>
        <Header title="Ghi chú riêng về khách" caption="Giúp các lần chăm sóc sau tốt hơn" />
        {changeLogs.length > 0 ? (
          <Tooltip
            title={
              <Stack sx={{ width: '500px', maxHeight: '400px', overflowY: 'scroll' }}>
                {changeLogs.map((item) => (
                  <CareLog data={item} />
                ))}
              </Stack>
            }
            placement="right"
            PopperProps={{
              sx: {
                '.MuiTooltip-tooltip': {
                  bgcolor: '#fff',
                  borderWidth: '1px',
                  borderColor: COLORS.DarkCharcoal,
                  borderRadius: '6px',
                  width: '540px',
                  maxWidth: 'none'
                }
              }
            }}
          >
            <Typography style={{ fontSize: '12px', color: COLORS.BlueJeans, fontWeight: '500', cursor: 'pointer' }}>Lịch sử chỉnh sửa</Typography>
          </Tooltip>
        ) : (
          <div />
        )}
      </Stack>
      <MyTextArea
        placeholder=""
        value={content}
        style={{
          height: '80px',
          fontSize: '12px'
        }}
        handleChange={setContent}
      />
      {content !== carer_note ? (
        <Stack direction={'row'} alignItems={'center'} sx={{ alignSelf: 'flex-end', marginTop: '8px' }}>
          <Button
            label="Huỷ"
            onClick={() => setContent(carer_note)}
            style={{ padding: '0px 0px', marginRight: '10px', backgroundColor: COLORS.bright_Gray }}
            labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.DarkCharcoal }}
          />
          <Button
            label="Cập nhật"
            onClick={async () => {
              await createLog(
                id,
                {
                  type: EnumCareLogType.CHANGE_NOTE,
                  content
                },
                'Cập nhật ghi chú của khách thành công'
              )
            }}
            style={{ padding: '0px 12px', backgroundColor: COLORS.DarkSpringGreen }}
            labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.white }}
          />
        </Stack>
      ) : (
        <div />
      )}
    </Stack>
  )
}

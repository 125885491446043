import { Box, Typography } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
/* eslint-disable consistent-return */
import { showApiError, showToast } from 'components'
import { useTableData } from 'components/Table/hook'
import { useSelector } from 'hooks'
import { EnumTable } from 'models'
import Api from 'services'
import { COLORS } from 'utils'
import * as XLSX from 'xlsx'
export function ImportBtn() {
  const { campaign } = useSelector((x) => x.campaign)
  const { brand } = useSelector((x) => x.care)
  const [rows, setRows] = useState<any[]>([])
  const { handleRemoteData } = useTableData(EnumTable.CUSTOMER_CARE_LIST)
  const fileInputRef = useRef<any>(null)
  const handleImport = useCallback(
    async (file: File) => {
      try {
        // if (!campaign) {
        //   return
        // }
        const data: { carer_username: string; customer_phone: string }[] = []
        const rows: any[] = await readExcel(file)
        if (rows.length === 0) {
          showToast({
            content: `Không có khách nào cần chỉ định chăm sóc`,
            type: 'warning'
          })
          return
        }
        for (var row of rows) {
          const carer = row?.['col0']
          if (!carer) {
            continue
          }
          const carer_username = carer?.split('-')?.pop()?.trim()
          if (!carer_username) {
            continue
          }
          const customer_phone = row?.['col2']
          if (!customer_phone) {
            continue
          }
          data.push({ carer_username, customer_phone })
        }
        if (data.length === 0) {
          showToast({ content: 'Không có dữ liệu hợp lệ, vui lòng Tải file danh sách KH trước' })
          return
        }
        showToast({ content: `Hệ thống đang nhập nhân viên chăm sóc, vui lòng chờ chút.`, type: 'info', duration: 10000 })
        Api.customer_care
          .assign_multiple({
            data,
            campaign: campaign?.id,
            brand
          })
          .then((res) => {
            const updatedCount = res?.data?.data ?? 0
            if (updatedCount === 0) {
              showToast({ content: 'Không có khách hàng nào cần cập nhật lại nhân viên chăm sóc' })
              return
            }
            showToast({ content: `Thay đổi nhân viên chăm sóc trực tiếp cho ${updatedCount} khách. `, type: 'success', duration: 3000 })
            handleRemoteData()
          })
          .catch((e) => {
            showApiError(e)
          })
      } catch (e) {
        showToast({
          content: 'Có lỗi xảy ra',
          type: 'error'
        })
      }
    },
    [rows, campaign?.id, brand]
  )

  const readExcel = useCallback(async (file?: File): Promise<any[]> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const binaryStr = e.target?.result
        const workbook = XLSX.read(binaryStr, { type: 'binary' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const jsonData: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })

        const dataRows = jsonData.slice(1).map((row, index) => {
          const rowData: Record<string, any> = {}
          row.forEach((cell, cellIndex) => {
            rowData[`col${cellIndex}`] = cell
          })
          return { id: index, ...rowData }
        })
        resolve(dataRows)
      }
      reader.readAsBinaryString(file)
    })
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 2 }}>
      <Box
        onClick={() => {
          fileInputRef?.current?.click()
        }}
        sx={{
          cursor: 'pointer',
          px: 1,
          py: 0.5,
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: COLORS.bright_Gray
          }
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '500',
            color: COLORS.AuroMetalSaurus,
            '&:hover': {
              color: COLORS.CelticBlue
            }
          }}
        >
          3. Import lại file vào hệ thống
        </Typography>
      </Box>
      <input
        ref={fileInputRef}
        hidden
        accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        type="file"
        onChange={(e) => {
          const file = e.target.files?.[0]
          if (!file) {
            return
          }
          handleImport(file)
        }}
      />
    </Box>
  )
}

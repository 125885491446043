import { MyTextArea, MyTextField } from 'components'
import { DatePickerCustom } from 'components/DatePicker'
import { DateTimePickerCustom } from 'components/DateTimePicker'
import { DropdownPicker } from 'components/dropdown_picker'
import { InputConfig } from 'models'
import React, { FC } from 'react'

export const InputWrapper: FC<InputConfig<any>> = ({
  hidden,
  type,
  url,
  data: options,
  label,
  name,
  filterFunc,
  error_msg,
  placeholder,
  require,
  disabled,
  getLabel,
  value,
  onChange
}) => {
  if (hidden) {
    return <div />
  }

  if (type === 'dropdown') {
    return (
      <DropdownPicker
        label={label}
        url={url}
        require={require}
        filterFunc={filterFunc}
        options={options}
        selectOption={(item) => {
          onChange?.(item)
        }}
        getOptionLabel={(option: any) => getLabel?.(option) ?? ''}
        // TODO: Dynamic compare
        isOptionEqualToValue={(a: any, b: any) => a === b ?? a?.id === b?.id}
        value={value}
        disabled={disabled}
      />
    )
  }
  if (type === 'dropdown_multiple') {
    return (
      <DropdownPicker
        multiple
        label={label}
        require={require}
        filterFunc={filterFunc}
        url={url}
        options={options}
        selectOptions={(items) => {
          onChange?.(items)
        }}
        getOptionLabel={(option: any) => getLabel?.(option) ?? ''}
        // TODO: Dynamic compare
        isOptionEqualToValue={(a, b) => a === b ?? a?.id === b?.id}
        value={value ?? []}
        disabled={disabled}
      />
    )
  }
  if (type === 'input_date') {
    return (
      <DatePickerCustom
        label={label}
        placeholder={placeholder ?? label}
        disabled={disabled}
        require={require}
        value={value}
        handleChange={(val) => {
          onChange?.(val)
        }}
      />
    )
  }
  if (type === 'input_datetime') {
    return (
      <DateTimePickerCustom
        label={label}
        placeholder={placeholder ?? label}
        disabled={disabled}
        require={require}
        value={value}
        handleChange={(val) => {
          onChange?.(val)
        }}
      />
    )
  }
  if (type === 'input_text' || type === 'input_number') {
    return (
      <MyTextField
        // width="100%"
        label={label}
        type={type === 'input_text' ? 'text' : 'number'}
        value={value}
        require={require}
        placeholder=""
        handleChange={(val) => {
          onChange?.(val)
        }}
        disabled={disabled}
        err_msg={error_msg}
      />
    )
  }
  if (type === 'input_textarea') {
    return (
      <MyTextArea
        label={label}
        value={value}
        require={require}
        placeholder=""
        handleChange={(val) => {
          onChange?.(val)
        }}
        disabled={disabled}
        err_msg={error_msg}
      />
    )
  }
  return undefined
}

import { Grid, Stack } from '@mui/material'
import { useSelector } from 'hooks'
import React from 'react'
import { Contacts } from './contacts'
import { NewContact } from './new_contact'
import { CarerNote } from './carer_note'
import { UnitCarer } from './unit_carer'

export function BrandCare() {
  const { care } = useSelector((x) => x.care)
  if (!care) {
    return <div />
  }

  return (
    <Stack>
      <Grid container rowSpacing={3}>
        <Grid item xs={5.5}>
          <UnitCarer />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5.5}>
          <CarerNote />
        </Grid>

        <Grid item xs={5.5}>
          <Contacts />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5.5}>
          <NewContact />
        </Grid>
      </Grid>
    </Stack>
  )
}

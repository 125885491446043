/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CareStateEntity, CustomerCareEntity, EnumBrand, OrgUnitEntity, StatisticItem } from 'models'

const initialState: CareStateEntity = {
  statistic_items: {}
}
const slice = createSlice({
  name: 'CARE_STATE',
  initialState,
  reducers: {
    setBrand: (state, { payload }: PayloadAction<EnumBrand>) => {
      state.brand = payload
    },
    setCustomerCare: (state, { payload }: PayloadAction<CustomerCareEntity | null>) => {
      state.care = payload
    },
    setFocusStore: (state, { payload }: PayloadAction<OrgUnitEntity | undefined>) => {
      state.focus_store = payload
    },
    addStatistic: (state, { payload }: PayloadAction<StatisticItem[]>) => {
      payload.forEach((item) => {
        state.statistic_items[item.id] = item
      })
    }
  }
})
export const { actions: careActions, reducer: careReducer } = slice

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { Button, DropdownPicker } from 'components'
import { env } from 'config'
import { useSelector } from 'hooks'
import { useCustomerCare } from 'hooks/customer_care'
import { EnumCareLogType, OrgUnitEntity, UserEntity } from 'models'
import React, { useEffect, useState } from 'react'
import { COLORS } from 'utils'
import { CareLog } from './care_log'
import { Header } from './header'
import {getChangeLogsByType} from './common'

export function UnitCarer() {
  const { care } = useSelector((x) => x.care)
  const { createLog } = useCustomerCare()
  if (!care) {
    return <div />
  }
  const { unit_carer, logs, id } = care
  const [carer, setCarer] = useState<UserEntity>(care.carer)

  useEffect(() => {
    setCarer(care.carer)
  }, [care.carer])

  const changeLogs =getChangeLogsByType(care, EnumCareLogType.CHANGE_CARER)
  return (
    <Stack>
      <Stack direction={'row'}>
        <Header title="Cửa hàng" caption="Thực hiện chăm sóc khách" />
        {changeLogs.length > 0 ? (
          <Tooltip
            title={
              <Stack sx={{ width: '500px', maxHeight: '400px', overflowY: 'scroll' }}>
                {changeLogs.map((item) => (
                  <CareLog data={item} />
                ))}
              </Stack>
            }
            placement="right"
            PopperProps={{
              sx: {
                '.MuiTooltip-tooltip': {
                  bgcolor: '#fff',
                  borderWidth: '1px',
                  borderColor: COLORS.DarkCharcoal,
                  borderRadius: '6px',
                  width: '540px',
                  maxWidth: 'none'
                }
              }
            }}
          >
            <Typography style={{ fontSize: '12px', color: COLORS.BlueJeans, fontWeight: '500', cursor: 'pointer' }}>Lịch sử chỉnh sửa</Typography>
          </Tooltip>
        ) : (
          <div />
        )}
      </Stack>
      <Stack direction={'row'}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <DropdownPicker disabled={true} placeholder="Cửa hàng" value={unit_carer ?? null} getOptionLabel={(a: OrgUnitEntity) => a.name} />
        </Box>
        {unit_carer != null ? (
          <Box sx={{ display: 'flex', flex: 1, ml: '20px' }}>
            <DropdownPicker
              value={carer}
              placeholder="Nhân viên cửa hàng"
              getOptionLabel={(a: UserEntity) => a.name}
              getOptionCaption={(a: UserEntity) => a.company}
              style={{}}
              url={`${env.api.customer_care}/organization-units/${unit_carer.id}/users`}
              selectOption={(u: UserEntity) => {
                if (!u) {
                  return
                }
                setCarer(u)
              }}
            />
          </Box>
        ) : (
          <div />
        )}
      </Stack>

      {carer?.id !== care.carer?.id ? (
        <Stack direction={'row'} sx={{ justifyContent: 'end', marginTop: '8px' }}>
          <Button
            label="Huỷ"
            onClick={() => setCarer(care.carer)}
            style={{ padding: '0px 0px', marginRight: '10px', backgroundColor: COLORS.bright_Gray }}
            labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.DarkCharcoal }}
          />
          <Button
            label="Cập nhật"
            onClick={() => {
              if (!carer) {
                return
              }
              createLog(
                id,
                {
                  type: EnumCareLogType.CHANGE_CARER,
                  carer: carer.id
                },
                `Đã chỉ định nhân viên ${carer.name} chăm sóc khách ${care.customer?.name ?? ''}`
              )
            }}
            style={{ padding: '0px 12px', backgroundColor: COLORS.DarkSpringGreen }}
            labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.white }}
          />
        </Stack>
      ) : (
        <div />
      )}
    </Stack>
  )
}

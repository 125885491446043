import { EnumBrand } from './campaign'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'
import { PermissionEntity } from './permissions'
import { GroupPermissionEntity } from './permissions/role'
import { StoreEntity } from './store'

export enum EnumUserStatus {
  WORKING = 'WORKING',
  LEAVING = 'LEAVING',
  LEAVE_ARMY = 'LEAVE_ARMY',
  LEAVE_UNPAID = 'LEAVE_UNPAID',
  LEAVE_SICK = 'LEAVE_SICK',
  LEAVE_STUDY = 'LEAVE_STUDY',
  STOP_WORKING = 'STOP_WORKING'
}

export interface UserEntity extends EntityBase {
  name: string
  avatar: string
  position?: string
  flag_no_assign_customer?: boolean
  email: string
  is_admin: boolean
  username: string
  status: EnumUserStatus
  bod?: string
  password: string
  telegram?: string
  zalo?: string

  phone: string
  address: string
  stores?: StoreEntity[]
  is_verified: boolean
  group_permissions?: GroupPermissionEntity[]
  permissions?: PermissionEntity[]
  device_token: string[]
  active: boolean
  company: string
  created_at: Date
  updated_at: Date
  store_units: OrgUnitEntity[]
  brand?: EnumBrand
}

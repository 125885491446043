import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const update = (id: string, data: ApiRequest.campaign.update) => instanceAxios.patch(`/campaigns/${id}`, data)

const show = async (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}`)

const guide_link = async (): Promise<any> => instanceAxios.get(`/campaigns/guide-link`)

const employee_distribute = (id: string, data: ApiRequest.campaign.employee_distribute): Promise<any> =>
  instanceAxios.post(`/campaigns/${id}/distribute/employees`, data)
const store_distribute = (id: string, data: ApiRequest.campaign.store_distribute): Promise<any> =>
  instanceAxios.post(`/campaigns/${id}/distribute/stores`, data)
const employee_regain = (id: string, data: ApiRequest.campaign.employee_regain): Promise<any> => instanceAxios.post(`/campaigns/${id}/regain/employee`, data)
const store_regain = (id: string, data: ApiRequest.campaign.store_regain): Promise<any> => instanceAxios.post(`/campaigns/${id}/regain/store`, data)
export default {
  update,
  show,
  employee_distribute,
  store_distribute,
  employee_regain,
  store_regain,
  guide_link
}

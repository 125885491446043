import { EntityBase } from 'models/entity'
import { EnumModule } from './module'

export interface PermissionEntity extends EntityBase {
  module: EnumModule
  action: EnumAction
  label?: string
}
export enum EnumAction {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
  DELETE = 'delete',
  SYNC_PORTAL = 'sync_portal',
  CAMPAIGN_VIEW_ENDED_CAMPAIGNS = 'campaign_view_ended_campaigns',
  CAMPAIGN_IMPORT_CUSTOMER = 'campaign_import_customer',
  CAMPAIGN_EXPORT_CUSTOMER = 'campaign_export_customer',
  CAMPAIGN_DISTRIBUTE_CUSTOMER_TO_EMPLOYEE = 'campaign_distribute_customer_to_employee',
  CAMPAIGN_DISTRIBUTE_CUSTOMER_TO_STORE = 'campaign_distribute_customer_to_store',
  CAMPAIGN_EXPORT_STORE_CARER = 'campaign_export_store_carer',
  CAMPAIGN_CARE_CUSTOMER = 'campaign_care_customer',
  CAMPAIGN_UPDATE_TEMPLATE = 'campaign_update_template',
  CAMPAIGN_VIEW_STORE_STATISTIC = 'campaign_view_store_statistic',
  CAMPAIGN_VIEW_ALL_STORES = 'campaign_view_all_stores'
}

export const ALL_PERMISSIONS: Partial<PermissionEntity>[] = [
  // Module Đợt chăm sóc
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.VIEW,
    label: 'Xem đợt chăm sóc đang chạy'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_VIEW_ENDED_CAMPAIGNS,
    label: 'Xem đợt chăm sóc đã xong'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.UPDATE,
    label: 'Sửa thông tin'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_IMPORT_CUSTOMER,
    label: 'Import khách hàng'
  },

  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_IMPORT_CUSTOMER,
    label: 'Import code/voucher'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_EXPORT_CUSTOMER,
    label: 'Export khách hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_VIEW_STORE_STATISTIC,
    label: 'Xem thống kê của cửa hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_DISTRIBUTE_CUSTOMER_TO_STORE,
    label: 'Phân phối khách cho cửa hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_DISTRIBUTE_CUSTOMER_TO_EMPLOYEE,
    label: 'Phân phối khách cho nhân viên'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_CARE_CUSTOMER,
    label: 'Chăm sóc khách hàng'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_UPDATE_TEMPLATE,
    label: 'Cập nhật template liên hệ'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CAMPAIGN_VIEW_ALL_STORES,
    label: 'Xem toàn bộ cửa hàng'
  },
  // Module dữ liệu portal
  {
    module: EnumModule.PORTAL_DATA,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.PORTAL_DATA,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.PORTAL_DATA,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ'
  },
  // Module Phân quyền
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  }
]

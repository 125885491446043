import { Stack } from '@mui/material'
import { Form } from 'components'
import { useSelector } from 'hooks'
import {
  BrandLabel,
  CustomerCareEntity,
  CustomerEntity,
  CustomerGenderLabel,
  EnumBrand,
  EnumCustomerGender,
  EnumFormatRank,
  FormatRankLabel,
  FormConfig,
  InputConfig
} from 'models'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
interface Props {}

export const Information: FC<Props> = ({}) => {
  const dispatch = useDispatch()
  const { care } = useSelector((x) => x.care)

  const TKL_FIELDS: InputConfig<any>[] = [
    { label: 'Tên khách', name: 'name', require: true, type: 'input_text', grid_xs: 3 },
    { label: 'Số điện thoại', require: true, name: 'phone', type: 'input_text', grid_xs: 3 },
    {
      label: 'Giới tính',
      name: 'gender',
      type: 'dropdown',
      getLabel: (a: EnumCustomerGender) => CustomerGenderLabel[a],
      grid_xs: 3
    },
    { label: 'Sinh nhật', name: 'birthday', type: 'input_date', grid_xs: 3, placeholder: 'Không có thông tin' },
    {
      label: 'Doanh thu',
      name: 'revenue',
      type: 'input_text',
      grid_xs: 3
    },
    {
      label: 'Tiêu điểm',
      name: 'tkl_score_amount',
      type: 'input_text',
      grid_xs: 3
    },
    {
      label: 'Hạn tiêu điểm',
      name: 'tkl_score_expire',
      type: 'input_date',
      placeholder: 'Không có thông tin',
      grid_xs: 3
    }
  ]

  const FM_FIELDS: InputConfig<any>[] = [
    { label: 'Tên khách', name: 'name', require: true, type: 'input_text', grid_xs: 3 },
    { label: 'Số điện thoại', require: true, name: 'phone', type: 'input_text', grid_xs: 3 },
    {
      label: 'Giới tính',
      name: 'gender',
      type: 'dropdown',
      getLabel: (a: EnumCustomerGender) => CustomerGenderLabel[a],
      grid_xs: 3
    },
    { label: 'Sinh nhật', name: 'birthday', type: 'input_date', grid_xs: 3, placeholder: 'Không có thông tin' },
    {
      label: 'Hạng Format',
      name: 'fm_rank',
      type: 'dropdown',
      getLabel: (a: EnumFormatRank) => (a && a !== EnumFormatRank.UNKNOWN ? FormatRankLabel[a] : ''),
      grid_xs: 3
    },
    {
      label: 'Doanh thu',
      name: 'revenue',
      type: 'input_text',
      grid_xs: 3
    },
    {
      label: 'Tiêu điểm',
      name: 'fm_score_amount',
      type: 'input_text',
      grid_xs: 3
    },
    {
      label: 'Hạn tiêu điểm',
      name: 'fm_score_expire',
      type: 'input_date',
      placeholder: 'Không có thông tin',
      grid_xs: 3
    }
  ]

  const Config: FormConfig = {
    inputs: care.brand === EnumBrand.Tokyolife ? TKL_FIELDS : FM_FIELDS,
    header: '',
    data: care?.customer ?? {}
  }

  return (
    <Stack sx={{ my: 1 }}>
      <Form disabled non_dialog config={Config} onComplete={(res) => {}} />
    </Stack>
  )
}

import { Box, Typography } from '@mui/material'
import { Button, showApiError, showToast } from 'components'
import { useSelector } from 'hooks'
import { isString } from 'lodash'
import { EnumCareType } from 'models'
import React, { FC, useCallback, useEffect } from 'react'
import Api from 'services'
import { COLORS } from 'utils'

export function ExportBtn() {
  const { campaign } = useSelector((x) => x.campaign)
  const { focus_store, brand } = useSelector((x) => x.care)
  const handleExport = useCallback(() => {
    if (!campaign) {
      showToast({
        content: 'Tính năng xuất khách hàng quen của cửa hàng đang tạm khoá',
        type: 'info'
      })
      return
    }
    if (!focus_store) {
      showToast({
        content: 'Vui lòng chọn cửa hàng trước, vì hệ thống chỉ hỗ trợ nhập nhân viên chăm sóc theo cửa hàng',
        type: 'info'
      })
      return
    }
    showToast({
      content: 'Đang lấy dữ liệu, vui lòng chờ chút...',
      type: 'info',
      duration: 5000
    })
    Api.customer_care
      .index({
        unit_carer: focus_store.id,
        care_type: campaign ? EnumCareType.CAMPAIGN : EnumCareType.BRAND,
        campaign: campaign?.id,
        brand,
        assignee_select: true,
        export_excel: true
      })
      .then((res: any) => {
        const link = res?.data?.data
        if (!isString(link)) {
          showToast({
            content: 'Không tìm thấy link tải xuống ',
            type: 'warning'
          })
          return
        }
        showToast({
          content: 'Tải xuống danh sách khách hàng thành công',
          type: 'success'
        })
        window.location.href = link
        // onFirstExport(link)
      })
      .catch((e) => {
        showApiError(e)
      })
  }, [campaign, brand, focus_store])
  return (
    <Box
      onClick={handleExport}
      sx={{
        cursor: 'pointer',
        px: 1,
        py: 0.5,
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: COLORS.bright_Gray
        }
      }}
    >
      <Typography
        sx={{
          fontSize: '13px',
          fontWeight: '500',
          color: COLORS.AuroMetalSaurus,
          '&:hover': {
            color: COLORS.CelticBlue
          }
        }}
      >
        1.Tải file khách hàng
      </Typography>
    </Box>
  )
}

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumImportCategory } from 'models'
import { ImportExcelConfig, ImportExcelState } from 'models/redux/import_excel'

const initialState: ImportExcelState = {
  import_list: []
}
const slice = createSlice({
  name: 'IMPORT_EXCEL_STATE',
  initialState,
  reducers: {
    resetImportList: (state) => {
      state.import_list = []
    },
    addExcelImport: (state, { payload }: PayloadAction<ImportExcelConfig>) => {
      var existed = state.import_list?.find((u) => u.code === payload.code) != null
      if (existed) {
        return
      }
      if (!state.import_list) {
        state.import_list = []
      }
      state.import_list.push({
        code: payload.code,
        data: [],
        category: EnumImportCategory.ALL,
        config: payload,
        whitelist_column_keys: payload.template ? payload.template?.columns.map((item) => item.key) : []
      })
    },
    setFile: (state, { payload }: PayloadAction<{ code: string; file: File | undefined }>) => {
      var i = state.import_list?.findIndex((u) => u.code === payload.code) ?? -1
      if (i === -1) {
        return
      }
      state.import_list[i].file = payload.file
      if (payload.file === undefined) {
        state.import_list[i].is_preview = false
      }
    },
    selectColumn: (state, { payload }: PayloadAction<{ code: string; column_key: string }>) => {
      var i = state.import_list?.findIndex((u) => u.code === payload.code) ?? -1
      if (i === -1) {
        return
      }

      var cols = state.import_list[i].whitelist_column_keys ?? []
      var col_selected = cols?.includes(payload.column_key)
      if (col_selected) {
        state.import_list[i].whitelist_column_keys = cols.filter((u) => u !== payload.column_key)
      } else {
        state.import_list[i].whitelist_column_keys = [...cols, payload.column_key]
      }
    },
    setPreview: (state, { payload }: PayloadAction<{ code: string; preview: boolean }>) => {
      var i = state.import_list?.findIndex((u) => u.code === payload.code) ?? -1
      if (i === -1) {
        return
      }
      state.import_list[i].is_preview = payload.preview
    },
    selectCategory: (state, { payload }: PayloadAction<{ code: string; category: EnumImportCategory }>) => {
      var i = state.import_list?.findIndex((u) => u.code === payload.code) ?? -1
      if (i === -1) {
        return
      }
      state.import_list[i].category = payload.category
    },
    setData: (state, { payload }: PayloadAction<{ code: string; data: any[] }>) => {
      var i = state.import_list?.findIndex((u) => u.code === payload.code) ?? -1
      if (i === -1) {
        return
      }
      state.import_list[i].data = payload.data
    }
  }
})
export const { actions: importExcelActions, reducer: importExcelReducer } = slice

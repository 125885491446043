import { GridColDef } from '@mui/x-data-grid'
import { AttachmentEntity } from 'models/attachment'
import { EnumExcelTemplate, ExcelTemplate } from 'models/excel_template'

export enum EnumImportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportExcelData {
  code: string // is template_code or any string with import file which has not template_code
  file?: File
  is_preview?: boolean
  data?: any[]
  category?: EnumImportCategory
  config?: ImportExcelConfig

  whitelist_column_keys: string[]
}
export interface ImportExcelState {
  import_list: ImportExcelData[]
}
export interface ImportExcelConfig {
  code: string
  only_preview?: boolean
  isValidItem: (a: any) => boolean
  template_code?: EnumExcelTemplate
  template?: ExcelTemplate
  submit_request: {
    url: string
    preview_param?: string
    params: object
    onComplete?: () => void
  }
  columns?: GridColDef[]
  customRowData?: (a: any) => any

  imported_file?: AttachmentEntity[]
  template_url?: string
}

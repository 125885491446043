/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isNumber } from 'lodash'
import { EnumTable, PaginationEntity } from 'models'
import { TableDataEntity, TableStateEntity } from 'models/redux/table'
import { InputConfig, TableConfig } from 'models/table'

export const convertToParams = (data: any) => {
  // TODO
  const params: any = {}
  // const params = state.config?.predefine_params ?? {}
  Object.keys(data).forEach((key) => {
    // const { multiple } = state.config.filters?.inputs?.find((item) => item.name === key) ?? {}
    const value = data[key]
    if (value === undefined || value === null) {
      return
    }
    const type = typeof value
    if (type === 'string' || isNumber(value) || type === 'boolean') {
      // TODO:
      params[key] = value
      return
    }
    if (Array.isArray(value)) {
      params[key] = value.map((item) => (typeof item === 'object' ? item.id : item))
      return
    }
    if (type === 'object') {
      if (value.id) {
        params[key] = value.id
      }
    }
  })
  return params
}

const getTable = (state: TableStateEntity, code: EnumTable) => {
  return state.tables.find((table) => table.config?.code === code)
}
const initialState: TableStateEntity = {
  tables: []
}
const slice = createSlice({
  name: 'TABLE_STATE',
  initialState,
  reducers: {
    //  Set up
    setupTable: (state, { payload }: PayloadAction<TableConfig<any>>) => {
      const newTable: TableDataEntity = {
        config: payload,
        pageIndex: 1,
        pageSize: 10
      }

      const i = state.tables.findIndex((item) => item.config.code === payload.code)
      if (i === -1) {
        state.tables.push(newTable)
      } else {
        state.tables[i] = newTable
      }
    },

    setFilterInputs: (state, { payload }: PayloadAction<{ code: EnumTable; inputs: InputConfig<any>[] }>) => {
      const i = state.tables.findIndex((item) => item.config.code === payload.code)
      if (i === -1) {
        return
      }
      if (state.tables[i].config.filters?.inputs) {
        state.tables[i].config.filters.inputs = payload.inputs
      }
    },

    // Select
    setLoading: (state, { payload }: PayloadAction<{ code: EnumTable; loading: boolean }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.loading = payload.loading
    },
    selectRow: (state, { payload }: PayloadAction<{ item: any; code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      if (!table.select) {
        table.select = {
          items: []
        }
      }
      const selectedItem = payload.item
      let items = table.select?.items ?? []
      const selected = items.find((item) => item.id === selectedItem.id)
      const multiple_select = table.config.select?.multiple
      if (selected) {
        items = multiple_select ? table.select.items.filter((item) => item.id !== selectedItem.id) : []
      } else if (multiple_select) {
        items.push(selectedItem)
      } else {
        items = [selectedItem]
      }
      table.select!.items = items
    },
    selectRows: (state, { payload }: PayloadAction<{ items: any[]; code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      if (!table.select) {
        table.select = {
          items: []
        }
      }
      payload.items.forEach((item) => {
        if (!table.select!.items.find((i2) => i2.id === item.id)) {
          table.select!.items.push(item)
        }
      })
    },
    resetSelect: (state, { payload }: PayloadAction<{ code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.select = {
        items: [],
        select_all: false
      }
    },
    selectAllPages: (state, { payload }: PayloadAction<EnumTable>) => {
      const table = getTable(state, payload)
      if (!table) {
        return
      }
      if (!table.select) {
        table.select = {
          items: [],
          select_all: false
        }
      }
      // const local_data = table
      // if (table.data?.all !== undefined) {

      // }
      if (table.select?.select_all) {
        table.select.select_all = false
        table.select.items = []
      } else {
        table.select.select_all = true
        table.select.items = []
      }
    },

    // Set data
    setData: (state, { payload }: PayloadAction<{ data: any[]; code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.data = payload.data
    },
    setAllData: (state, { payload }: PayloadAction<{ data: any[]; code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.all_data = payload.data
    },
    setExcludeData: (state, { payload }: PayloadAction<{ data: any[]; code: EnumTable }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.exclude_data = payload.data
    },

    // Pagination
    setPageIndex: (state, { payload }: PayloadAction<{ code: EnumTable; value: number }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.pageIndex = payload.value
    },
    setPageSize: (state, { payload }: PayloadAction<{ code: EnumTable; value: number }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.pageSize = payload.value
    },
    setPagination: (state, { payload }: PayloadAction<{ code: EnumTable; pagination: PaginationEntity }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.pagination = payload.pagination
    },

    // Filter
    changeFilter: (state, { payload }: PayloadAction<{ name: EnumTable; data: any }>) => {
      const table = getTable(state, payload.name)
      if (!table) {
        return
      }

      const filter = table.filter ?? { data: {}, params: {} }
      filter.data = {
        ...filter.data,
        ...payload.data
      }
      filter.params = convertToParams(filter.data)
      table.filter = filter
    },
    resetFilter: (state, { payload }: PayloadAction<EnumTable>) => {
      const table = getTable(state, payload)
      if (!table) {
        return
      }
      table.filter = { data: {}, params: {} }
    },
    setSortField: (state, { payload }: PayloadAction<{ code: EnumTable; sort_field: string | undefined }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.sort_field = payload.sort_field
    },
    setSortAsc: (state, { payload }: PayloadAction<{ code: EnumTable; sort_asc: boolean }>) => {
      const table = getTable(state, payload.code)
      if (!table) {
        return
      }
      table.sort_asc = payload.sort_asc
    }
  }
})
export const { actions: tableActions, reducer: tableReducer } = slice

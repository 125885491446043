import { Stack, Typography } from '@mui/material'
import React, { FC } from 'react'
/* eslint-disable consistent-return */
import { PermissionWrapper } from 'components'
import { EnumAction, EnumModule } from 'models'
import { COLORS } from 'utils'
import { ExportBtn } from './export_btn'
import { ImportBtn } from './import_btn'
import { useSelector } from 'hooks'
interface Props {}
export const CarerImport: FC<Props> = ({}) => {
  const { focus_store } = useSelector((x) => x.care)
  if (!focus_store) {
    return <div />
  }
  return (
    <PermissionWrapper module={EnumModule.CAMPAIGN} action={EnumAction.CAMPAIGN_DISTRIBUTE_CUSTOMER_TO_EMPLOYEE}>
      <Stack direction={'column'} sx={{ mb: '6px' }}>
        <Typography
          style={{ fontSize: '13px', fontWeight: '500', color: COLORS.AuroMetalSaurus }}
        >{`Nhập người chăm sóc cho ${focus_store?.name ?? ''} : `}</Typography>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <ExportBtn />
          <Typography style={{ fontSize: '13px', fontWeight: '500', color: COLORS.AuroMetalSaurus }}>2. Điền nhân viên vào file</Typography>
          <ImportBtn />
        </Stack>
      </Stack>
    </PermissionWrapper>
  )
}

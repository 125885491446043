import { EntityBase } from './entity'

export enum EnumExcelTemplate {
  CAMPAIGN_FORMAT_CUSTOMER = 'campaign_format_customer',
  CAMPAIGN_TOKYOLIFE_CUSTOMER = 'campaign_tokyolife_customer'
}

export interface IColumn {
  key: string
  label: string
  required: boolean
  description: string
}
export interface ExcelTemplate extends EntityBase {
  code: EnumExcelTemplate
  name: string
  columns: IColumn[]
  deleted_at: Date
}

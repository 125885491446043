import { useSelector } from 'hooks'
import _ from 'lodash'
import { EnumTable, ListEntity } from 'models'
import { TableConfig } from 'models/table'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store/slices/table'
import { instanceAxios } from 'utils'

export const PerPageOptions = [5, 10, 50, 100]
export function useTableConfig({ config, code }: { config?: TableConfig<any>; code?: EnumTable }) {
  const { tables } = useSelector((x) => x.table)
  const dispatch = useDispatch()

  const setup = useCallback(() => {
    if (!config) {
      return
    }
    dispatch(tableActions.setupTable(config))
  }, [config, dispatch])

  const table = tables.find((item) => item.config.code === config?.code || item.config.code === code)
  const hasTable = table != null
  useEffect(() => {
    if (config && !hasTable) {
      setup()
    }
  }, [config, setup, hasTable])

  return { config: config ?? table?.config, ready: table !== undefined }
}

export function useTableData<ItemT>(code: EnumTable) {
  const { tables } = useSelector((x) => x.table)
  const table = tables.find((item) => item.config.code === code)
  const dispatch = useDispatch()

  const { data, all_data, loading, pageIndex, pageSize, config, pagination } = table ?? {}

  const { url, method } = config ?? {}

  // TODO
  const handleRemoteData = useCallback(async () => {
    if (!config?.code) {
      return
    }
    const requestParams = {
      ...(table?.filter?.params ?? {}),
      page: pageIndex ?? 1,
      per_page: pageSize ?? 10
    }

    dispatch(tableActions.setLoading({ code: config?.code, loading: true }))
    instanceAxios({
      url,
      method,
      ...(method === 'post' ? { data: requestParams } : { params: requestParams })
    })
      .then((response) => {
        const res: ListEntity<ItemT> = response.data.data

        dispatch(tableActions.setData({ code: config?.code, data: res.data }))
        dispatch(tableActions.setPagination({ code: config.code, pagination: res.pagination }))
      })
      .catch(() => {})
      .finally(() => {
        dispatch(tableActions.setLoading({ code: config?.code, loading: false }))
      })
  }, [config?.code, table?.filter?.params, pageIndex, pageSize, url, method, dispatch])

  const handleLocalData = useCallback(() => {
    if (!config?.code) {
      return
    }
    // TODO: dislay all data
    const allData = table?.all_data ?? []
    const i = (pageIndex ?? 1 - 1) * (pageSize ?? 10)
    const pageData = allData.slice(i, i + (pageSize ?? 10))
    dispatch(
      tableActions.setData({
        code: config?.code,
        data: pageData
      })
    )
  }, [config?.code, dispatch, pageIndex, pageSize, table?.all_data])

  const isSelectAny = useCallback(() => {
    return table?.select?.select_all || (table?.select?.items && table.select.items.length > 0)
  }, [table?.select])

  const isExcludedRow = useCallback(
    (item: any) => {
      const exclude_ids = _.map(table?.exclude_data ?? [], 'id')
      return exclude_ids.includes(item.id)
    },
    [table?.exclude_data]
  )

  return {
    data,
    all_data,
    table,
    pagination,
    loading,
    pageIndex,
    pageSize,
    isExcludedRow,
    isSelectAny,
    handleRemoteData,
    handleLocalData
  }
}

import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { lazy, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Loadable, ProtectedAuth } from 'components'
import { BASE_THEME } from 'constant'
import { useAppInit } from 'hooks'
import { EnumBrand, MenuUrl } from 'models'
import { persistor, store } from 'store'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))

const CampaignList = Loadable(lazy(() => import('pages/campaign/list')))
const CampaignEdit = Loadable(lazy(() => import('pages/campaign/edit')))

const BrandCare = Loadable(lazy(() => import('pages/brand_care')))
const PortalData = Loadable(lazy(() => import('pages/portal_data')))

const GroupPermissionList = Loadable(lazy(() => import('pages/permission/list')))
const GroupPermissionDetail = Loadable(lazy(() => import('pages/permission/detail')))
function AppRoutes() {
  const [loading, setLoading] = useState<boolean>(true)
  useAppInit(setLoading)

  return (
    !loading && (
      <BrowserRouter>
        <Routes>
          <Route path="/404" element={<PageNotFound />} />
          <Route
            path="/"
            element={
              <ProtectedAuth>
                <MainLayout />
              </ProtectedAuth>
            }
          >
            <Route path={MenuUrl.campaign} element={<CampaignList />} />
            <Route path={`${MenuUrl.campaign}/detail/:id`} element={<CampaignEdit />} />

            <Route path={MenuUrl.brand_care_format} element={<BrandCare brand={EnumBrand.Format} />} />
            <Route path={MenuUrl.brand_care_tokyolife} element={<BrandCare brand={EnumBrand.Tokyolife} />} />

            <Route path={MenuUrl.portal_data} element={<PortalData />} />

            <Route path={MenuUrl.group_permission} element={<GroupPermissionList />} />
            <Route path={`${MenuUrl.group_permission}/:id`} element={<GroupPermissionDetail />} />
          </Route>

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    )
  )
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { showToast } from 'components'
import { CareStatusLabel, CustomerCareEntity, CustomerGenderLabel, EnumUserStatus, FormatRankLabel } from 'models'
import React from 'react'
import { COLORS, formatDateEn } from 'utils'
const getShortCustomerName = (u: string) => {
  if (!u) {
    return '-'
  }
  var max_trim_len = 12
  var len = u.length
  if (len < max_trim_len) {
    return u
  }
  return '...' + u.substring(len - max_trim_len, len)
}
export const FMCareColumns = [
  {
    header: {
      label: 'Tên khách'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.customer?.name ?? '',
      width: 200
    }
  },
  {
    header: {
      label: 'Trạng thái'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => CareStatusLabel[a.status],
      width: 120
    }
  },
  {
    header: {
      label: 'Code/voucher'
    },
    cell: {
      getElement: (a: CustomerCareEntity) =>
        a.promos && a.promos.length ? (
          <Typography
            style={{ fontSize: '12px', color: COLORS.DarkCharcoal, fontWeight: '400' }}
          >{`Đã dùng ${a.used_promos?.length ?? 0} / ${a.promos?.length ?? 0}`}</Typography>
        ) : (
          <div />
        ),
      width: 80
    }
  },
  {
    header: {
      label: 'SĐT'
    },
    cell: {
      getElement: (a: CustomerCareEntity) => (
        <Tooltip title="Copy số">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => {
              showToast({
                content: `Đã copy số điện thoại của khách "${a.customer?.name ?? ''}"`
              })
              navigator.clipboard.writeText(a.customer?.phone ?? '')
            }}
          >
            <ContentCopyIcon sx={{ fontSize: '16px', marginLeft: '6px', color: COLORS.LightSkyBlue }} />
          </Box>
        </Tooltip>
      ),
      width: 60
    }
  },
  {
    header: {
      label: 'Sinh nhật'
    },
    cell: { getValue: (a: CustomerCareEntity) => (a.customer?.birthday ? formatDateEn(a.customer?.birthday) : '-') }
  },
  {
    header: {
      label: 'Hạng'
    },
    cell: { getValue: (a: CustomerCareEntity) => (a.customer?.fm_rank ? FormatRankLabel[a.customer.fm_rank] : '-'), width: 120 }
  },
  {
    header: {
      label: 'Tiêu điểm'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.customer?.fm_score_amount?.toLocaleString() ?? '-',
      width: 80
    }
  },
  {
    header: {
      label: 'Hạn điểm'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.fm_score_expire ? formatDateEn(a.customer?.fm_score_expire) : '-'),
      width: 100
    }
  },
  {
    header: {
      label: 'Nhân viên'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.carer ? `${a.carer?.name} ${a.carer?.status === EnumUserStatus.STOP_WORKING ? '(Nghỉ)' : ''}` : '-'),
      width: 200
    }
  },
  {
    header: {
      label: 'Cửa hàng'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.unit_carer?.name?.replace(/format /gi, 'F ') ?? '-',
      width: 160
    }
  },
  {
    header: {
      label: 'Doanh thu'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a?.revenue?.toLocaleString() ?? '-',
      width: 100
    }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.gender != undefined ? CustomerGenderLabel[a.customer.gender] : '-'),
      width: 80
    }
  },
  {
    header: {
      label: 'Ghi chú'
    },
    cell: { getValue: (a: CustomerCareEntity) => a.customer?.fm_note ?? '-' }
  }
]

export const TKLCareColumns = [
  {
    header: {
      label: 'Tên khách'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.customer?.name ?? '-',
      width: 200
    }
  },
  {
    header: {
      label: 'Trạng thái'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => CareStatusLabel[a.status],
      width: 160
    }
  },
  {
    header: {
      label: 'SĐT'
    },
    cell: {
      getElement: (a: CustomerCareEntity) => (
        <Tooltip title="Copy số">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => {
              showToast({
                content: `Đã copy số điện thoại của khách "${a.customer?.name ?? ''}"`
              })
              navigator.clipboard.writeText(a.customer?.phone ?? '')
            }}
          >
            <ContentCopyIcon sx={{ fontSize: '16px', marginLeft: '6px', color: COLORS.CelticBlue + '88' }} />
          </Box>
        </Tooltip>
      ),
      width: 60
    }
  },
  {
    header: {
      label: 'Sinh nhật'
    },
    cell: { getValue: (a: CustomerCareEntity) => (a.customer?.birthday ? formatDateEn(a.customer?.birthday) : '-') }
  },
  {
    header: {
      label: 'Nhân viên'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.carer ? `${a.carer?.name} ${a.carer?.status === EnumUserStatus.STOP_WORKING ? '(Nghỉ)' : ''}` : '-'),
      width: 200
    }
  },
  {
    header: {
      label: 'Cửa hàng'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.unit_carer?.name?.replace(/tokyolife/gi, 'T ') ?? '-',
      width: 200
    }
  },
  {
    header: {
      label: 'Tiêu điểm'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.customer?.tkl_score_amount?.toLocaleString() ?? '-',
      width: 80
    }
  },
  {
    header: {
      label: 'Hạn điểm'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.tkl_score_expire ? formatDateEn(a.customer?.tkl_score_expire) : '-'),
      width: 120
    }
  },
  {
    header: {
      label: 'Doanh thu'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => a.revenue?.toLocaleString() ?? '-',
      width: 120
    }
  },
  {
    header: {
      label: 'Giới tính'
    },
    cell: {
      getValue: (a: CustomerCareEntity) => (a.customer?.gender != undefined ? CustomerGenderLabel[a.customer.gender] : '-')
    }
  },
  {
    header: {
      label: 'Ghi chú'
    },
    cell: { getValue: (a: CustomerCareEntity) => a.customer?.tkl_note ?? '-' }
  }
]

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined'
import { Box, Tooltip } from '@mui/material'
import { showToast, Table } from 'components'
import { useTableConfig, useTableData } from 'components/Table/hook'
import { env } from 'config'
import { useSelector } from 'hooks'
import { useCustomerCare } from 'hooks/customer_care'
import dayjs from 'dayjs'
import {
  CareStatusCaption,
  CareStatusLabel,
  CustomerCareEntity,
  CustomerCareSortFields,
  CustomerCareSortLabel,
  CustomerGenderLabel,
  EnumBrand,
  EnumCareStatus,
  EnumCareType,
  EnumCustomerCareSortField,
  EnumFormatRank,
  EnumOrgUnitType,
  EnumTable,
  EnumUserStatus,
  FormatRankLabel,
  InputConfig,
  OrgUnitEntity,
  StoreEntity,
  TableConfig,
  UserEntity
} from 'models'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { careActions, tableActions } from 'store'
import { COLORS, createSlug, formatDateEn } from 'utils'
import { CareDetail } from './detail'
import { FMCareColumns, TKLCareColumns } from './config'

export function CareList({ brand, store_units }: { brand: EnumBrand; store_units: OrgUnitEntity[] }) {
  const { table } = useTableData(EnumTable.CUSTOMER_CARE_LIST)
  const { campaign } = useSelector((x) => x.campaign)
  const dispatch = useDispatch()

  const FilterInput: InputConfig<any>[] = [
    {
      label: 'Hạng Format',
      type: 'dropdown',
      data: [EnumFormatRank.MEMBER, EnumFormatRank.NEAR_VIP, EnumFormatRank.SILVER, EnumFormatRank.GOLD, EnumFormatRank.DIAMOND, EnumFormatRank.ROYAL],
      name: 'fm_rank',
      getLabel: (a: EnumFormatRank) => FormatRankLabel[a]
    },
    {
      label: 'Khách đã có code/voucher',
      type: 'dropdown',
      data: [true, false],
      name: 'has_promo',
      getLabel: (a: any) => (a === true ? 'Khách đã được gửi qua app,..' : 'Khách chưa có ưu đãi nào')
    },
    {
      label: 'Khách đã dùng code/voucher',
      type: 'dropdown',
      data: [true, false],
      name: 'used_promo',
      getLabel: (a: any) => (a === true ? 'Đã sử dụng một vài ưu đãi' : 'Chưa sử dụng ưu đãi nào')
    },
    {
      label: 'Cửa hàng',
      type: 'dropdown',
      data: store_units ?? [],
      filterFunc: (u: OrgUnitEntity, searchKey: string) => createSlug([u.name]).includes(createSlug([searchKey])),
      name: 'unit_carer',
      getLabel: (a: OrgUnitEntity) => a.name?.replace(/^tokyolife /i, 'T ')?.replace(/^format /i, 'F '),
      getCaption: (a: OrgUnitEntity) => a.leader?.name ?? ''
    },
    {
      label: 'Trạng thái chăm sóc',
      type: 'dropdown',
      data: [
        EnumCareStatus.FIND_UNIT_CARER,
        EnumCareStatus.FIND_CARER,
        EnumCareStatus.NOT_CONTACT_YET,
        EnumCareStatus.UNABLE_CONTACT,
        EnumCareStatus.CONTACT_DONE
      ],
      name: 'status',
      getLabel: (a: EnumCareStatus) => CareStatusLabel[a],
      getCaption: (a: EnumCareStatus) => CareStatusCaption[a]
    },
    {
      label: 'Nhân viên chăm sóc',
      type: 'dropdown',
      url: `${env.api.customer_care}/users`,
      name: 'carer',
      getLabel: (a: UserEntity) => `${a.name}`,
      getCaption: (a: UserEntity) => `${a.company}`
    },
    {
      label: 'Sinh nhật từ',
      type: 'input_datetime',
      name: 'birthday__from',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM') : '')
    },
    {
      label: 'Sinh nhật đến',
      type: 'input_datetime',
      name: 'birthday__to',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM') : '')
    },
    {
      label: 'Hạn điểm từ',
      type: 'input_date',
      name: 'score_expire__from',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM/YYYY') : '')
    },
    {
      label: 'Hạn điểm đến',
      type: 'input_date',
      name: 'score_expire__to',
      getLabel: (a: string | undefined) => (a ? dayjs(a).format('DD/MM/YYYY') : '')
    }
  ]

  console.log('Brand is Format: ', brand, brand === EnumBrand.Format)

  const Config: TableConfig<any> = {
    code: EnumTable.CUSTOMER_CARE_LIST,
    search: {
      placeholder: 'Tìm theo tên khách, số điện thoại'
    },
    row_action_first: true,
    filters: {
      inputs: FilterInput
    },
    sort_by_input: {
      placeholder: 'Sắp xếp',
      data: [undefined, ...CustomerCareSortFields],
      getLabel: (a: EnumCustomerCareSortField) => (a == null ? 'Không sắp xếp' : CustomerCareSortLabel[a])
    },
    columns: brand === EnumBrand.Format ? FMCareColumns : TKLCareColumns,
    url: `${env.api.customer_care}/customer-cares`,
    row_actions: [
      {
        label: 'Xem thông tin',
        action_type: 'view'
      }
    ]
  }
  const { config } = useTableConfig({ config: Config })

  const campaign_id = campaign?.id
  useEffect(() => {
    dispatch(
      tableActions.changeFilter({
        name: EnumTable.CUSTOMER_CARE_LIST,
        data: {
          campaign: campaign_id ? campaign_id : null,
          brand: !campaign_id ? brand : campaign.type,
          care_type: campaign_id ? EnumCareType.CAMPAIGN : EnumCareType.BRAND
        }
      })
    )
  }, [campaign_id, dispatch, brand])

  useEffect(() => {
    dispatch(
      tableActions.changeFilter({
        name: EnumTable.CUSTOMER_CARE_LIST,
        data: {
          sort_field: table?.sort_field,
          sort_asc: table?.sort_asc
        }
      })
    )
  }, [table?.sort_field, table?.sort_asc, dispatch])

  const { getDetail } = useCustomerCare()

  const [open, setOpen] = useState<boolean>(false)
  const { care } = useSelector((x) => x.care)
  useEffect(() => {
    setOpen(care != undefined)
  }, [care])

  return (
    <>
      <CareDetail
        open={open}
        onClose={() => {
          dispatch(careActions.setCustomerCare(null))
        }}
      />
      <Table
        code={Config.code}
        onItemAction={(action_type, item) => {
          if (action_type === 'view') {
            showToast({ content: `Đang lấy thông tin khách hàng...`, type: 'info' })
            getDetail(item.id)
          }
        }}
      />
    </>
  )
}

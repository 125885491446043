import { EnumBrand } from './campaign'
import { EntityBase } from './entity'
import { OrgUnitEntity } from './org_unit'

export enum EnumFormatRank {
  NEAR_VIP = 'Cận VIP',

  GOLD = 'Vàng',
  DIAMOND = 'Kim cương',
  ROYAL = 'Hoàng Kim',
  SILVER = 'Bạc',
  SINGLE_CUSTOMER = 'Khách lẻ',
  MEMBER = 'Thành viên',
  EMPLOYEE = 'Nhân viên',

  UNKNOWN = 'Không xác định'
}
export const FormatRankLabel: Record<EnumFormatRank, string> = {
  'Cận VIP': 'Cận VIP',
  'Khách lẻ': 'Khách lẻ',
  'Thành viên': 'Thành viên',
  'Nhân viên': 'Nhân viên',
  Vàng: 'Vàng',
  'Kim cương': 'Kim cương',
  'Hoàng Kim': 'Hoàng Kim',
  Bạc: 'Bạc',
  'Không xác định': 'Không xác định'
}
export enum EnumCustomerGender {
  Female = 'female',
  Male = 'male',
  Unknown = 'unknown'
}
export interface CustomerEntity extends EntityBase {
  // Common fields
  brands?: EnumBrand[]
  name: string
  odoo_code: string
  slug: string
  gender: EnumCustomerGender
  phone: string
  birthday: string
  odoo_created_at: Date
  odoo_updated_at: Date
  odoo_sync_at: Date
  address: string
  deleted_at?: Date

  // Tokyolife fields
  tkl_score_amount: number
  tkl_score_expire: Date
  tkl_note: string
  tkl_revenue: number

  // Format fields
  fm_rank: EnumFormatRank
  fm_store?: OrgUnitEntity
  fm_score_amount: number
  fm_score_expire: Date
  fm_revenue: number
  fm_note: string
}

export const CustomerGenderLabel: Record<EnumCustomerGender, string> = {
  [EnumCustomerGender.Female]: 'Nữ',
  [EnumCustomerGender.Male]: 'Nam',
  [EnumCustomerGender.Unknown]: ''
}

export interface ImportCustomerEntity extends Partial<CustomerEntity> {
  fm_store_id: string
  tkl_store_id: string
  valid_flag: boolean
}

import { Tooltip } from '@mui/material'
import { Button, showApiError, showToast } from 'components'
import { useSelector } from 'hooks'
import { isString } from 'lodash'
import React, { useCallback, useState } from 'react'
import Api from 'services'

export const TemplateFile = ({ code }: { code: string }) => {
  const { import_list } = useSelector((x) => x.import_excel)
  const { config, whitelist_column_keys } = import_list?.find((u) => u.code === code) ?? {}
  const { template_code, template_url } = config ?? {}
  const [enable, setEnable] = useState<boolean>(true)

  const downloadFile = useCallback((url: string) => {
    showToast({
      content: 'Tải template thành công',
      type: 'success'
    })
    window.location.href = url
  }, [])
  console.log('Enable: ', enable, template_url)

  const handleDownload = useCallback(async () => {
    if (!enable) {
      return
    }
    if (template_url) {
      downloadFile(template_url)
      return
    }
    if (!template_code) {
      showToast({
        content: 'Chưa cấu hình tệp template',
        type: 'warning'
      })
      return
    }
    setEnable(false)
    setTimeout(() => {
      setEnable(true)
    }, 10000)
    Api.excel_template
      .getFile({
        template_code,
        keys: whitelist_column_keys
      })
      .then((res) => {
        console.log('Res: ', res)

        var link = res?.data?.data
        if (!link || !isString(link)) {
          showToast({
            content: 'Không thể tải xuống file',
            type: 'warning'
          })
          return
        }
        downloadFile(link)
      })
      .catch((e) => {
        showApiError(e)
      })
  }, [template_code, downloadFile, enable, whitelist_column_keys])

  return (
    <Tooltip title={`Tải template và điền thông tin theo các cột, rồi import lại vào web.`}>
      <Button
        style={{
          paddingTop: '2px',
          paddingBottom: '2px'
        }}
        label={enable ? 'Tải template' : 'Đang tải file mẫu...'}
        type={enable ? 'primary' : 'default'}
        labelStyle={{
          fontSize: '12px'
        }}
        onClick={handleDownload}
      />
    </Tooltip>
  )
}

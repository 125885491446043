import { CustomerCareEntity, ListEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.customer_care.index): Promise<ListEntity<CustomerCareEntity>> => instanceAxios.get('/customer-cares', { params })

const show = async (id: string): Promise<any> => instanceAxios.get(`customer-cares/${id}`)

const mapStore = async (campaign: string): Promise<any> => instanceAxios.post(`customer-cares/map-store`, { campaign })

const user_statistic = (params: ApiRequest.customer_care.user_stats): Promise<any> =>
  instanceAxios.get('customer-cares/statistic/user', {
    params
  })

const create_log = async (id: string, params: ApiRequest.customer_care.create_log) => instanceAxios.post(`customer-cares/${id}/log`, params)

const store_statistic = (params: ApiRequest.customer_care.store_stats): Promise<any> =>
  instanceAxios.get('customer-cares/statistic/store', {
    params
  })

const assign_single = (data: ApiRequest.campaign.assign_single) => instanceAxios.patch(`/customer-cares/carer/assign`, data)
const assign_multiple = (data: ApiRequest.campaign.assign_multiple) => instanceAxios.patch(`/customer-cares/carer/bulk-assign`, data)

export default {
  user_statistic,
  store_statistic,
  create_log,
  assign_multiple,
  assign_single,
  mapStore,
  index,
  show
}

import { Box, Stack, Typography } from '@mui/material'
import { CareLogEntity, ContactMethodLabel, EnumCareLogType } from 'models'
import moment from 'moment'
import React, { useCallback } from 'react'
import { COLORS } from 'utils'
export function CareLog({ data }: { data: CareLogEntity }) {
  const { contact_success, created_at, created_by, content, method, type, carer } = data

  console.log('cate log: ', data)
  const getContent = useCallback(() => {
    if (method != null) {
      return `Đã ${ContactMethodLabel[method]?.toLocaleLowerCase()} cho khách, ghi chú: "${content}"`
    }
    switch (type) {
      case EnumCareLogType.ADD_CONTACT_LOG:
        return `Đã ${ContactMethodLabel[method]?.toLocaleLowerCase()} cho khách, ghi chú: "${content}"`
      case EnumCareLogType.CHANGE_CARER:
        return `Đã chỉ định "${carer?.name ?? ''}" chăm sóc khách này.`
      case EnumCareLogType.CHANGE_NOTE:
        return `Đã sửa thành: "${content}"`
      default:
        return ''
    }
  }, [type, contact_success, method, carer, content])

  return (
    <Stack direction={'row'} sx={{ my: 1 }}>
      {method != null || type === EnumCareLogType.ADD_CONTACT_LOG ? (
        <Box
          sx={{
            height: '10px',
            width: '10px',
            borderRadius: '10px',
            alignSelf: 'center',
            backgroundColor: contact_success ? COLORS.DarkSpringGreen : COLORS.RedSalsa + 'AA'
          }}
        />
      ) : (
        <Box />
      )}
      <Stack direction={'column'} sx={{ marginLeft: '10px', display: 'flex', flex: 1 }}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{}}>
          <Typography style={{ fontSize: '12px', color: COLORS.DarkCharcoal, fontWeight: '500' }}>{created_by?.name ?? ''}</Typography>
          <Typography style={{ fontSize: '11px', color: COLORS.AuroMetalSaurus, fontWeight: '500' }}>
            {moment(created_at).format('HH:mm DD/MM/YYYY')}
          </Typography>
        </Stack>
        <Typography style={{ fontSize: '12px', color: COLORS.nickel }}>{getContent()}</Typography>
      </Stack>
    </Stack>
  )
}

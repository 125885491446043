import CloseIcon from '@mui/icons-material/Close'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Drawer, Stack } from '@mui/material'
import { IconButton, Section } from 'components'
import { useSelector } from 'hooks'
import React from 'react'
import { COLORS } from 'utils'
import { CampaignCares } from './campaign_care'
import { Information } from './customer_infor'
import { BrandCare } from './brand_care'
import { useCustomerCare } from 'hooks/customer_care'
import { EnumCareType } from 'models'

interface Props {
  open: boolean
  onClose?: () => void
}

export function CareDetail({ open, onClose }: Props) {
  const { campaign } = useSelector((x) => x.campaign)
  const { care } = useSelector((x) => x.care)
  const { getDetail } = useCustomerCare()
  return (
    <Drawer open={open} onClose={onClose} anchor="right" sx={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          width: '80vw',
          padding: '10px 20px',
          flexDirection: 'column',
          backgroundColor: '#F7F7F7'
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%', mb: 3 }}>
          <IconButton
            onClick={() => onClose?.()}
            label="Đóng"
            icon={
              <CloseIcon
                sx={{
                  fontSize: '20px',
                  color: COLORS.DarkCharcoal
                }}
              />
            }
          />
          <Stack direction="row" alignItems="center" spacing={3}>
            {care?.id && (
              <IconButton
                onClick={() => getDetail(care?.id)}
                label="Refresh thông tin khách hàng"
                icon={
                  <RefreshIcon
                    sx={{
                      fontSize: '20px',
                      color: COLORS.DarkCharcoal
                    }}
                  />
                }
              />
            )}
          </Stack>
        </Stack>
        {care ? (
          <>
            <Section label="Thông tin" defaultExpand={true}>
              <Information />
            </Section>

            {care.campaign_cares && campaign != null && (
              <Section label="Chăm sóc theo đợt" defaultExpand={true}>
                <CampaignCares />
              </Section>
            )}

            {care.care_type === EnumCareType.BRAND && (
              <Section label="Chăm sóc trực tiếp" defaultExpand={true}>
                <BrandCare />
              </Section>
            )}
            {care.campaign_cares && campaign == null && (
              <Section label="Chăm sóc theo đợt" defaultExpand={true}>
                <CampaignCares />
              </Section>
            )}
          </>
        ) : (
          <div />
        )}
      </div>
    </Drawer>
  )
}

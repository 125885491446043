import Box from '@mui/material/Box'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useSelector } from 'hooks'
import { EnumImportCategory, IColumn } from 'models'
import * as React from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export function Data({ code }: { code: string }) {
  const dispatch = useDispatch()
  const { import_list } = useSelector((x) => x.import_excel)
  const { data, category, config, whitelist_column_keys } = import_list?.find((u) => u.code === code) ?? {}
  const { template_code } = config ?? {}
  const isShowItem = useCallback(
    (item: any) => {
      if (!config?.isValidItem) {
        return true
      }
      const valid = config?.isValidItem(item)
      switch (category) {
        case EnumImportCategory.ALL:
          return true
        case EnumImportCategory.INVALID:
          return !valid
        case EnumImportCategory.VALID:
          return valid
        default:
          return false
      }
    },
    [category, config]
  )

  const getColumns = useCallback(() => {
    if (!config?.template) {
      return []
    }
    const columns: GridColDef[] = config.template.columns
      .filter((item) => whitelist_column_keys?.includes(item.key))
      .map(({ key, label, required }: IColumn) => ({
        field: key,
        headerName: label + (required ? '(*)' : ''),
        width: 120
      }))

    return columns
  }, [config?.template, template_code, whitelist_column_keys])

  const columns = config?.columns ?? getColumns()
  if (!config) {
    return <div />
  }
  const displayData = data?.filter((item: any) => isShowItem(item))?.map((u) => (config.customRowData ? config.customRowData(u) : u)) ?? []

  return (
    <Box sx={{ height: '500px', maxWidth: '1200px' }}>
      <DataGrid
        rows={displayData}
        columns={columns}
        getRowId={(item) => item.index}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[10, 50, 100]}
      />
    </Box>
  )
}

import { showApiError, showToast } from 'components'
import { useSelector } from 'hooks'
import { isString } from 'lodash'
import { EnumCareType, UserEntity } from 'models'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Api, { ApiRequest } from 'services'
import { careActions } from 'store'

export const useCustomerCare = () => {
  const { user } = useSelector((x) => x.profile)
  const { campaign } = useSelector((x) => x.campaign)
  const { brand } = useSelector((x) => x.care)
  const dispatch = useDispatch()
  const getDetail = useCallback(
    (id: string) => {
      Api.customer_care
        .show(id)
        .then((res) => {
          dispatch(careActions.setCustomerCare(res.data.data))
        })
        .catch((e) => {})
    },
    [dispatch]
  )

  const createLog = useCallback(
    async (id: string, data: ApiRequest.customer_care.create_log, msg_success?: string) => {
      try {
        await Api.customer_care.create_log(id, data)
        getDetail(id)
        showToast({
          content: msg_success ?? 'Thao tác thành công',
          type: 'success'
        })
      } catch (e) {
        showApiError(e)
      }
    },
    [getDetail]
  )

  const getStoreStatistic = useCallback(
    (params?: { export_excel?: boolean; is_asc?: boolean }) => {
      const ids = user?.store_units?.map(({ id }) => id) ?? []
      if (ids.length === 0) {
        return
      }
      const body: ApiRequest.customer_care.store_stats = {
        ids,
        care_type: campaign ? EnumCareType.CAMPAIGN : EnumCareType.BRAND,
        ...(params ?? {})
      }
      if (campaign?.id) {
        body.campaign = campaign.id
      } else if (brand) {
        body.brand = brand
      } else {
        return
      }
      if (params?.export_excel) {
        showToast({
          content: 'Đang xuất dữ liệu, vui lòng chờ',
          type: 'info',
          duration: 5000
        })
      }

      Api.customer_care
        .store_statistic(body)
        .then((res) => {
          if (params?.export_excel) {
            const link = res?.data?.data
            if (!isString(link)) {
              showToast({
                content: `Tải file không thành công`,
                type: 'warning'
              })
              return
            }
            showToast({
              content: 'Tải file thành công',
              type: 'success'
            })
            window.location.href = link
            return
          }
          var stat_data = res?.data?.data
          if (!stat_data) {
            return
          }

          dispatch(careActions.addStatistic(stat_data))
        })
        .catch((e) => {})
    },
    [user?.store_units, campaign, brand]
  )

  const getUserStatistic = useCallback(
    (users: UserEntity[], params?: { export_excel?: boolean; is_asc?: boolean }) => {
      const ids = users.map(({ id }) => id)
      if (ids.length === 0) {
        return
      }
      const body: ApiRequest.customer_care.user_stats = {
        ids,
        care_type: campaign ? EnumCareType.CAMPAIGN : EnumCareType.BRAND,
        ...(params ?? {})
      }
      if (campaign) {
        body.campaign = campaign.id
      } else if (brand) {
        body.brand = brand
      } else {
        return
      }

      if (params?.export_excel) {
        showToast({
          content: 'Đang xuất dữ liệu, vui lòng chờ',
          type: 'info',
          duration: 5000
        })
      }
      Api.customer_care
        .user_statistic(body)
        .then((res) => {
          if (params?.export_excel) {
            const link = res?.data?.data
            if (!isString(link)) {
              showToast({
                content: `Tải file không thành công`,
                type: 'warning'
              })
              return
            }
            showToast({
              content: 'Tải file thành công',
              type: 'success'
            })
            window.location.href = link
            return
          }
          var stat_data = res?.data?.data
          if (!stat_data) {
            return
          }
          dispatch(careActions.addStatistic(stat_data))
        })
        .catch((e) => {})
    },
    [campaign, brand]
  )
  return { getDetail, createLog, getStoreStatistic, getUserStatistic }
}

import { Card, Stack } from '@mui/material'
import { IconButton, globalPopup } from 'components'
import { EnumTable, TableSelectedAction } from 'models'
import React, { useCallback } from 'react'
import { useTableConfig, useTableData } from './hook'

interface Props {
  code: EnumTable
  onClick?: (action: string) => void
}
export function SelectedActions({ code, onClick }: Props) {
  const { config } = useTableConfig({ code })
  const { table, isSelectAny } = useTableData(code)

  const handleClick = useCallback(
    (action: TableSelectedAction) => {
      if (action?.id) {
        onClick?.(action?.id)
        return
      }
      if (!action.onClick) {
        return
      }
      if (!table?.select) {
        return
      }
      const dispatchClick = () => {
        action.onClick?.(table.select!, table?.filter?.params ?? {})
      }

      if (action.popup) {
        globalPopup.show({ title: action.popup.title, onConfirm: dispatchClick })
      } else {
        dispatchClick()
      }
    },
    [onClick, table?.filter?.params, table?.select]
  )

  if (!config?.select_actions) {
    return <></>
  }
  if (!isSelectAny) {
    return <></>
  }
  return (
    <Card sx={{ width: 'fit-content', marginLeft: 'auto', p: 1 }}>
      <Stack direction="row" spacing={3}>
        {config?.select_actions?.map((action) => <IconButton {...action} onClick={() => handleClick(action)} />)}
      </Stack>
    </Card>
  )
}

import { Box, FormControl, Typography } from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { IconOpenDatePicker } from 'assets'
import React from 'react'
import { formatDateEn } from 'utils'
import { InputLabel } from './InputLabel'

interface Props {
  label?: string
  value?: string
  require?: boolean
  disabled?: boolean
  handleChange: (value: any) => void
  minDate?: any
  placeholder?: string
}
export function DatePickerCustom({ label, value, disabled, handleChange, require, minDate, placeholder }: Props) {
  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} />}
      <Box
        sx={{
          '& fieldset': {
            border: 'none'
          },
          border: '1px solid #EEEEEE',
          borderRadius: '6px',
          width: '100%'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={value ?? null}
            disabled={disabled}
            inputFormat={'DD/MM/YYYY'}
            onChange={(time) => handleChange(time?.toISOString())}
            minDate={minDate || null}
            components={{
              OpenPickerIcon: IconOpenDatePicker
            }}
            PopperProps={{
              popperOptions: { placement: 'bottom-end', modifiers: [{ name: 'offset', options: { offset: [40, 6] } }] }
            }}
            PaperProps={styles}
            showDaysOutsideCurrentMonth
            renderInput={(params) => {
              return (
                <TextField
                  sx={{
                    '& label, & div': {
                      fontFamily: 'Be Vietnam Pro, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px'
                    }
                  }}
                  fullWidth
                  size="small"
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder
                  }}
                />
              )
            }}
            dayOfWeekFormatter={(day) => {
              switch (day) {
                case 'T2':
                  return 'M'
                case 'T3':
                  return 'T'
                case 'T4':
                  return 'W'
                case 'T5':
                  return 'T'
                case 'T6':
                  return 'F'
                case 'T7':
                  return 'S'
                case 'CN':
                  return 'S'
                default:
                  return day
              }
            }}
          />
        </LocalizationProvider>
      </Box>
    </FormControl>
  )
}

const styles = {
  sx: {
    // width: '290px',
    fieldset: {
      border: 'none'
    },
    'div, button, p, span': {
      fontSize: '14px',
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '150%'
    },
    '.MuiPickersCalendarHeader-root': {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      justifyContent: 'center',
      margin: '14px 13px 11.33px 13px',
      maxHeight: '24px',
      minHeight: '24px',
      div: {
        fontFamily: 'Be Vietnam Pro, sans-serif',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#494E50'
      },
      '.MuiPickersFadeTransitionGroup-root': {
        paddingLeft: '91px',
        position: 'absolute'
      }
    },
    '.MuiPickersCalendarHeader-root:first-child': {
      order: 0,
      paddingRight: '20px',
      paddingLeft: '20px'
    },
    '.MuiPickersArrowSwitcher-root': {
      display: 'inline-flex'
      // visibility: "hidden"
    },
    '.MuiPickersCalendarHeader-label': {
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    '.MuiPickersArrowSwitcher-spacer': {
      width: '220px'
    },

    '.MuiPickersArrowSwitcher-button': {
      paddingRight: '7px'
    },
    '.MuiPickersDay-today': {
      color: '#2563EB',
      border: 'none !important',
      backgroundColor: 'transparent  !important'
    },
    '.MuiPickersDay-today::after': {
      content: '""',
      width: '4px',
      height: '4px',
      backgroundColor: '#2563EB',
      position: 'absolute',
      bottom: 0,
      right: '46%',
      borderRadius: '10px'
    },
    '.MuiCalendarPicker-root': {
      width: '290px',
      margin: 0
    },
    '.MuiCalendarOrClockPicker-root': {
      width: '289px'
    },
    '.Mui-selected': {
      backgroundColor: '#2563EB !important',
      color: '#FFF'
    },
    '.MuiDayPicker-slideTransition': {
      margin: '0 5px 8px 5px'
      // minHeight: '192px'
    },
    '.MuiPickersDay-root': {
      width: '40px',
      borderRadius: '8px',
      border: 'none !important'
    },
    '.MuiDayPicker-header': {
      height: '41px',
      backgroundColor: '#F5F7FA',
      margin: '0 5px',
      color: '#424242',
      span: {
        fontFamily: 'Roboto, sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '150%',
        color: '#424242'
      }
    }
  }
}

import { Box, Stack } from '@mui/material'
import { Button, DropdownPicker, MyTextArea } from 'components'
import { useSelector } from 'hooks'
import { useCustomerCare } from 'hooks/customer_care'
import { ContactMethodLabel, EnumCareLogType, EnumContactMethod } from 'models'
import React, { useCallback, useState } from 'react'
import { COLORS } from 'utils'
import { Header } from './header'

export function NewContact() {
  const { care } = useSelector((x) => x.care)
  if (!care) {
    return <div />
  }
  const [method, setMethod] = useState<EnumContactMethod>(EnumContactMethod.PHONE_CALL)
  const [contact_success, setContactSuccess] = useState<boolean>(true)
  const [content, setContent] = useState<string>('')
  const { createLog } = useCustomerCare()
  const { id } = care

  const handleReset = useCallback(() => {
    setMethod(EnumContactMethod.PHONE_CALL)
    setContactSuccess(true)
    setContent('')
  }, [])

  const handleCreate = useCallback(async () => {
    await createLog(
      id,
      {
        type: EnumCareLogType.ADD_CONTACT_LOG,
        content,
        method,
        contact_success
      },
      'Thêm lịch sử liên hệ thành công'
    )
    handleReset()
  }, [createLog, handleReset, method, contact_success, content, care.id])

  return (
    <Stack>
      <Header title="Thêm lịch sử liên hệ" caption="Khi chăm sóc khách xong, vui lòng thêm ở đây để theo dõi" />
      <Stack direction={'row'} sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', flex: 1, mr: '20px' }}>
          <DropdownPicker
            value={method}
            options={[
              EnumContactMethod.PHONE_CALL,
              EnumContactMethod.ZALO_CALL,
              EnumContactMethod.PHONE_MESSAGE,
              EnumContactMethod.ZALO_MESSAGE,
              EnumContactMethod.FACEBOOK
            ]}
            getOptionLabel={(a: EnumContactMethod) => ContactMethodLabel[a]}
            selectOption={(u: EnumContactMethod) => setMethod(u)}
            fullWidth
          />
        </Box>
        <Box sx={{ display: 'flex', flex: 1, mr: '20px' }}>
          <DropdownPicker
            value={contact_success}
            options={[true, false]}
            getOptionLabel={(a: boolean) => (a ? 'Liên hệ thành công' : 'Không liên hệ được (máy bận, số sai,...)')}
            selectOption={(u: boolean) => setContactSuccess(u)}
          />
        </Box>
      </Stack>
      <MyTextArea
        label=""
        placeholder="Thông tin trao đổi hoặc lý do không thể liên hệ"
        style={{ marginTop: '6px', height: '60px' }}
        value={content}
        handleChange={setContent}
      />
      <Stack direction={'row'} alignItems={'center'} sx={{ alignSelf: 'flex-end', marginTop: '8px' }}>
        <Button
          label="Huỷ"
          onClick={handleReset}
          style={{ padding: '0px 0px', marginRight: '10px', backgroundColor: COLORS.bright_Gray }}
          labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.DarkCharcoal }}
        />
        <Button
          label="Thêm "
          onClick={handleCreate}
          style={{ padding: '0px 12px', backgroundColor: COLORS.DarkSpringGreen }}
          labelStyle={{ fontSize: '12px', fontWeight: '500', color: COLORS.white }}
        />
      </Stack>
    </Stack>
  )
}

import { Stack } from '@mui/material'
import { Button, Tabs } from 'components'
import { useSelector } from 'hooks'
import { EnumImportCategory } from 'models'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { importExcelActions } from 'store/slices/import_excel'
interface Props {
  only_preview?: boolean
  code: string

  onImport?: (file: any) => void
}
export function Statistic({ onImport, only_preview, code }: Props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { import_list } = useSelector((x) => x.import_excel)
  const { data, category, file, config } = import_list?.find((u) => u.code === code) ?? {}

  console.log(
    'Statistic: ',
    code,
    import_list?.map((u) => u.code)
  )
  const errorItems = data?.filter((item: any) => !config?.isValidItem?.(item)) ?? []
  const validItems = data?.filter((item: any) => config?.isValidItem?.(item)) ?? []

  const TabItems: { label: string; value: EnumImportCategory }[] = [
    {
      label: `Toàn bộ (${data?.length ?? 0})`,
      value: EnumImportCategory.ALL
    },
    {
      label: `Hợp lệ (${validItems.length})`,
      value: EnumImportCategory.VALID
    },
    {
      label: `Không hợp lệ (${errorItems.length})`,
      value: EnumImportCategory.INVALID
    }
  ]
  return (
    <Stack sx={{ mx: 5, my: 1 }}>
      <Stack direction="row" alignItems="center" sx={{ justifyContent: 'space-between' }}>
        <Tabs
          data={TabItems}
          value={category}
          onSelect={(a) => {
            dispatch(importExcelActions.selectCategory({ code, category: a as EnumImportCategory }))
          }}
        />
        {data && data.length > 0 && !only_preview && (
          <Button type="primary" label={loading ? 'Đang nhập...' : 'Nhập dữ liệu'} onClick={() => onImport?.(file)} />
        )}
      </Stack>
    </Stack>
  )
}

import group_permission from 'services/group_permission'
import { PermissionEntity } from './permissions'
import { EnumModule } from './permissions/module'
export interface MenuEntity {
  href?: string
  title: string
  section_id?: SectionID
  permissions: Partial<PermissionEntity>[]
  id: EnumModule
  only_admin?: boolean
}
export interface SectionEntity {
  icon?: JSX.Element
  title: string
  id: SectionID
  items?: MenuEntity[]
}
export type SectionID = 'management' | 'role' | 'customer_care'

export const MenuUrl = {
  campaign: '/campaign',
  brand_care_format: '/brand_care_format',
  brand_care_tokyolife: '/brand_care_tokyolife',
  portal_data: '/portal_data',
  group_permission: '/group_permission'
}

import { EnumBrand } from './campaign'
import { CustomerEntity } from './customer'
import { EntityBase } from './entity'
import { StoreEntity } from './store'

export enum EnumPromoType {
  CODE = 'code',
  VOUCHER = 'voucher'
}

export interface PromoCode extends EntityBase {
  type: EnumPromoType
  value: string
  import_name: string
  brand: EnumBrand
  used_at: Date
  used_store: StoreEntity
  used_store_code: string

  used_phone: string
  customer: CustomerEntity
  promo_program_code: string
  total_voucher_price: number
  used_voucher_price: number
}

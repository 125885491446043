import { Stack, Typography } from '@mui/material'
import { useSelector } from 'hooks'
import { EnumCareLogType } from 'models'
import React from 'react'
import { COLORS } from 'utils'
import { CareLog } from './care_log'
import { Header } from './header'
import { getChangeLogsByType } from './common'

export function Contacts() {
  const { care } = useSelector((x) => x.care)
  if (!care) {
    return <div />
  }
  const { logs } = care

  const changeLogs = getChangeLogsByType(care, EnumCareLogType.ADD_CONTACT_LOG)

  if (changeLogs.length === 0) {
    return (
      <Typography style={{ fontSize: '13px', color: COLORS.AuroMetalSaurus, fontWeight: '500' }}>* Chưa có lịch sử chăm sóc riêng cho khách này</Typography>
    )
  }

  return (
    <Stack>
      <Header title="Lịch sử chăm sóc" caption="Lưu lại cuộc trao đổi giữa nhân viên và khách hàng." />
      <Stack sx={{ my: 1, maxHeight: '400px', overflowY: 'scroll' }}>
        {changeLogs.map((log) => (
          <CareLog data={log} />
        ))}
      </Stack>
    </Stack>
  )
}

import { Stack, Typography } from '@mui/material'
import React from 'react'
import { COLORS } from 'utils'

export function Header({ title, caption }: { title: string; caption: string }) {
  return (
    <Stack sx={{ display: 'flex', flex: 1 }}>
      <Typography style={{ fontSize: '13px', color: COLORS.DarkCharcoal, fontWeight: '600' }}>{title}</Typography>
      <Typography style={{ fontSize: '11px', color: COLORS.nickel }}>{caption}</Typography>
    </Stack>
  )
}
